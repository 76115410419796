<template>
  <section v-if="isGetStreamRequestPending">
    <div class="loading">
      <hub-icon name="loading" spin size="xxlg"></hub-icon>
    </div>
  </section>
  <section v-else class="invention-stream" :class="{ 'with-pane': !!activePane }">
    <div v-if="isGetStreamRequestFailed">An error occurred while an attempt to get event stream for this invention.</div>
    <div v-else ref="streamRootRef" style="display: flex; overflow-y: scroll; flex-direction: column-reverse; height: 100%">
      <ul class="stream-list">
        <div v-if="isMoreRequestPending" style="text-align: center"><hub-icon name="loading" spin size="lg"></hub-icon></div>
        <hub-observe v-else-if="total > collection.length" :get-root-ref="() => $refs['streamRootRef']" @intersect="more">
          <hub-button variant="text" @click="more">more<hub-icon name="refresh"></hub-icon></hub-button>
        </hub-observe>

        <li v-for="item of collection" :key="item.id" class="stream-list-item">
          <hub-message v-bind="item" @click="click(item)" @showForm="showForm(item)" />
        </li>
      </ul>
    </div>
    <div class="pane-wrapper">
      <hub-pane v-if="!!activePane" :name="activePane.name" :title="activePane.title" :data="activePane.data" @close="close" />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Icon from '../../common/Icon.vue';
import Button from '../../common/Button.vue';
import Observer from '../Observer.vue';
import Pane from '../Pane.vue';
import Message from '../Message.vue';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-observe': Observer,
    'hub-pane': Pane,
    'hub-message': Message
  },
  data() {
    return {
      activePane: null,
      to: new Date().toISOString()
    };
  },
  computed: {
    ...mapGetters({
      collection: 'stream/collection'
    }),
    ...mapState({
      isOnline: s => s.stream.isOnline,
      invention: s => s.inventions.item,
      total: s => s.stream.total,
      isGetStreamRequestPending: s => s.stream.isRequestPending,
      isGetStreamRequestFailed: s => s.stream.isRequestFailed,
      isMoreRequestPending: s => s.stream.isMoreRequestPending
    })
  },
  async created() {
    const to = new Date();
    await this.$store.dispatch('stream/load', { id: this.$route.params.id, to: new Date(+to + 2000).toISOString() });
    this.to = to.toISOString();

    this.$trackEvent(`Stream opened`);
    await this.$store.dispatch('stream/connect');
    this.$store.dispatch('stream/subscribe', { inventionId: this.$route.params.id });
  },
  async beforeUnmount() {
    await this.$store.dispatch('stream/unsubscribe', { inventionId: this.$route.params.id });
    await this.$store.dispatch('stream/disconnect');
  },
  methods: {
    async more() {
      await this.$store.dispatch('stream/load', { id: this.$route.params.id, skip: this.collection.length, to: this.to });
    },
    showAddTaskPane() {
      this.$trackEvent(`Add Task pane opened (stream)`);
      this.activePane = {
        name: 'tasks/CreateTask',
        title: 'Create Task',
        data: { inventionId: this.$route.params.id, task: { workflow: {}, createNew: true } }
      };
    },
    click(item) {
      switch (item.type) {
        case 'task:created':
        case 'task:updated':
        case 'task:migrated':
          this.activePane = {
            name: 'tasks/TaskValues',
            title: item.metadata.task.title,
            data: {
              task: item.metadata.task,
              invention: this.invention
            }
          };
          this.$trackEvent(`Edit Task pane opened (stream)`);
          break;

        default:
          this.activePane = null;
      }
    },
    showForm(item) {
      this.activePane = {
        name: 'tasks/FormValues',
        title: item.metadata.form.name,
        data: {
          properties: item.metadata.form.properties,
          invention: this.invention
        }
      };
    },
    async close() {
      this.activePane = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.invention-stream {
  height: 100%;
  display: grid;
  grid-gap: 0.5rem;
  overflow-y: scroll;

  grid-template-rows: 1fr;
  grid-template-columns: minmax(0, 1fr);

  &.with-pane {
    grid-template-columns: minmax(0, 1fr) 400px;
  }

  .pane-wrapper {
    background-color: var(--theme-surface);
  }
  > header {
    padding: 0.1rem;
    grid-column: 1/3;
    position: relative;
  }

  > footer {
    padding: 0 1.25rem;
    grid-column: 1/3;
  }
}

.stream-list {
  padding: 0.5rem 1.25rem;
  margin: 0;
  display: grid;
  grid-gap: 0.5rem;
  align-content: flex-end;
}

.stream-list-item {
  animation: append 0.5s ease-in;

  label {
    font-weight: 600;
  }

  .stream-list-item-header {
    margin-bottom: 0.25rem;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-device-width: 1023px), screen and (max-width: 1023px) {
  .pane-wrapper {
    z-index: 1;
  }
  .pane {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--theme-surface);
  }
}
</style>
