<template>
  <section class="pane">
    <header>
      <h3>{{ title }}</h3>
      <hub-button variant="icon" class="close-button" @click="$emit('close')"><hub-icon name="close"></hub-icon></hub-button>
    </header>
    <section>
      <component :is="component" v-if="component" :data="data" @close="$event => $emit('close', $event)" />
    </section>
  </section>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import Button from './../common/Button';
import Icon from './../common/Icon';

export default {
  components: {
    'hub-button': Button,
    'hub-icon': Icon
  },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  emits: ['close'],
  computed: {
    component() {
      if (!!this.name) {
        return defineAsyncComponent(() => import(`./${this.name}`));
      }

      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.pane {
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  width: 100%;

  header {
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr) max-content;
    grid-gap: 1rem;
    align-items: center;
    border-bottom: 1px solid var(--theme-highlight);
    padding: 0.75rem 1.25rem;
  }

  > section {
    overflow-y: auto;
    padding: 0.5rem 0.5rem 0 1rem;
    position: relative;
  }
}
</style>
